import React from 'react';
import {useRouteMatch, useLocation} from 'react-router-dom';
import Toolbar from "./components/Toolbar";
import Hero from './components/Hero';
import Permablok from './components/Permablok';
import Ecosense from './components/Ecosense';
import Paragraph from './components/Hero/components/Paragraph';
import Detail from './components/Detail';
import Footer from './components/Footer';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';
import './assets/styles/LandingMaglia.scss';
import './styles/app.scss';
import {IntlProvider} from "react-intl"
import es from "compiled-lang/es.json"
import en from "compiled-lang/en.json"
import de from "compiled-lang/de.json"
import fr from "compiled-lang/fr.json"
import it from "compiled-lang/it.json"
import pl from "compiled-lang/pl.json"

library.add(fas, fab);

function getMessages(lang) {
  switch (lang) {
    case 'es':
      return es
    case 'en':
      return en
    case 'fr':
      return fr
    case 'it':
      return it
    case 'pl':
      return pl
    case 'de':
      return de
    default:
      return en
  }
}

function Wrapper({children, col, subcol}) {
  return (
      <div className="App">
        <Toolbar/>
        <Hero/>
        <div className="container">
          <div className="row">
            {children}
            <div className={`col-lg-${col} col-xl-${col}`}>
              <div className="row">
                <div className={`col-lg-${subcol} col-xl-${subcol}`}>
                  <Permablok/>
                </div>
                <div className={`col-lg-${subcol} col-xl-${subcol}`}>
                  <Ecosense/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
  )
}

export default function App({detail}) {
  const location = useLocation()
  const match = useRouteMatch()

  const _md = () => {
    if (location.pathname.indexOf('detail') > -1)
      return {
        col: 6,
        subcol: 12
      }
    return {
      col: 12,
      subcol: 6
    }
  }

  const messages = getMessages(match.params.lang)

  return (
      <IntlProvider
          locale={match.params.lang}
          defaultLocale="en"
          messages={messages}>
        <Wrapper
            col={_md().col}
            subcol={_md().subcol}>
          {
            detail
                ?
                <div className="col-lg-6 col-xl-6">
                  <Detail/>
                </div>
                :
                <div className="col-lg-12 col-xl-12">
                  <Paragraph className="mt-5 outside-hero"/>
                </div>
          }
        </Wrapper>
      </IntlProvider>
  )
}