import React from 'react';
import Vimeo from '@u-wave/react-vimeo';
import './styles/ModalVideo.scss'
import {Modal, ModalBody} from "reactstrap"

export default function ModalVideo({isOpen, toggle}) {
  let url;

  switch (navigator.language) {
      case 'es':
        url = 'https://vimeo.com/485399406/edf00aee82'
        break
    case 'en':
      url = 'https://vimeo.com/482608256/cd361c9b90'
      break
    case 'fr':
      url = 'https://vimeo.com/482606770/98caa46f14'
      break
    case 'it':
      url = 'https://vimeo.com/482608968/58a5df8b16'
      break
    case 'pl':
      url = 'https://vimeo.com/483177113/978cbc814a'
      break
    case 'de':
      url = 'https://vimeo.com/482611310/ef59f87d5c'
      break
    default:
      // English one
      url = 'https://vimeo.com/482608256/cd361c9b90'
      break
  }

  return (
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalBody>
          <button type="button" className="close" onClick={toggle}>
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="player-wrapper mt-5">
            <Vimeo
                video={url}
                autoplay
                responsive={true}/>
          </div>
        </ModalBody>
      </Modal>
  )
}
