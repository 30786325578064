import React from "react";
import {Link} from "react-router-dom"
import "./styles/CarouselImage.scss"
import {useRouteMatch} from "react-router"

export default function CarouselImage({color, i, active}) {
  const match = useRouteMatch()

  return (
      <Link
          className={`carousel-image ${active ? 'carousel-image--active' : ''}`}
          to={`/${match.params.lang}/detail/${color.slug}`}>
        <div
            className="carousel-image__image"
            style={{backgroundImage: `url(${color.thumbMedium})`}}/>
        <h6 className="carousel-image__label">
          {color.label}
        </h6>
      </Link>
  )
}