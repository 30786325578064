import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import arrowBack from './img/back_arrow.svg';
import spradlingLogo from './img/spradling_logo.svg';
import './styles/toolbar.scss';

export default function Toolbar() {
  const toolbar = React.useRef();
  const location = useLocation();
  //const history = useHistory();
  const [ismobile, setIsmobile] = useState(null);

  useEffect(() => {
    function responsive(x) {
      if (x.matches) { // If media query matches
        //console.log('false movil');
        setIsmobile(false);
      } else {
        //console.log('true movil');
        setIsmobile(true);
      }
    }
    const x = window.matchMedia("(min-width: 755px)");
    responsive(x);
    x.addEventListener('change', responsive);
    window.onscroll = function (e) {
      //console.log(window.scrollY); // Value of scroll Y in px
      if (window.scrollY > 21) {
        toolbar.current.style.backgroundColor = '#fff';
      }else{
        toolbar.current.style.backgroundColor = 'transparent';
      }
    };
  }, []);

  // const handleBack = (e) => {
  //   history.goBack();
  // }

  const _backButton = () => {
    if (location.pathname !== '/' && ismobile) {
      return (
        <Link to="/">
          <img src={arrowBack} style={{ marginLeft: '0.5rem' }} width="20px" alt="..." />
        </Link>
      )
    }
  }

  const align = () => {
    if (location.pathname !== '/') {
      if (ismobile) {
        return 'justify-content-between';
      }
    }
  }

  return (
    <div className={`Toolbar p-0`} ref={toolbar}>
      <div className="container">
        <div className={`logo d-flex align-items-center ${align()}`}>
          {
            _backButton()
          }

          <Link className="navbar-brand" to="/">
            <img src={spradlingLogo} alt="..." />
          </Link>
        </div>
      </div>
    </div>
  )
}