import React from 'react';
import plants from './img/plants_2.jpg';
import logoEcosense from './img/logo_ecosense.svg';
import carbonNeutral from './img/carbon_neutral.svg';
import iso from './img/iso14001.svg';
import reach from './img/REACH.svg';
import rohs from './img/ROHS.svg';
import './styles/ecosense.scss'
import {FormattedMessage} from "react-intl"

export default function Ecosense() {
  return (
      <div className="Ecosense mt-5">
        <div className="description">
          <div className="container-images">
            <img className="img-fluid" src={plants} alt="plants"/>
            <div className="image-small">
              <img className="img-fluid" src={logoEcosense} alt="ecosense"/>
            </div>
          </div>
          <h4 className="text-center">
            <FormattedMessage
                id="EcoSense.higher"
                defaultMessage="Higher Purpose"/>
          </h4>
          <p>
            <FormattedMessage
                id="EcoSense.subtitle"
                defaultMessage="Behind every Spradling product, there is a higher purpose. Our design goes beyond improving spaces and satisfying the most demanding requirements. We are motivated by a commitment to the future, to have a positive impact on the environment and to improve the lives of the people and communities around us."/>
          </p>
          <div className="d-flex justify-content-between align-items-center">
            <img className="certs" src={carbonNeutral} alt="plants"/>
            <img className="certs" src={iso} alt="plants"/>
            <img className="certs" src={reach} alt="plants"/>
            <img className="certs" src={rohs} alt="plants"/>
          </div>
        </div>
      </div>
  )
}
