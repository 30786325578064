import React, {useContext} from 'react';
import {Col, Container, Modal, ModalBody, Row} from 'reactstrap';
import ModalContext from "./context";
import './styles/samplingrequest.scss';
import RequestForm from "components/SamplingRequest/components/RequestForm"

export default function SamplingRequest({isOpen, toggle}) {
  const context = useContext(ModalContext);

  return (
      <Modal isOpen={isOpen} toggle={toggle} className="modal-lg sampling-request-modal">
        <ModalBody>
          <Container>
            <Row>
              <Col md={12}>
                <button type="button" className="close" onClick={toggle}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <h3 className="text-center">{context.title}</h3>
              </Col>
            </Row>
            {
              isOpen &&
              <RequestForm toggle={toggle}/>
            }
          </Container>
        </ModalBody>
      </Modal>
  )
}
