export default [
  {
    label: 'Açai',
    value: 'açai',
    slug: 'acai',
    sub: 'MAG-6030',
    thumbSmall: require('./images/collection/small/acai.jpg'),
    thumbMedium: require('./images/collection/acai.jpg'),
  },
  {
    label: 'Arctic',
    value: 'arctic',
    slug: 'arctic',
    sub: 'MAG-6017',
    thumbSmall: require('./images/collection/small/arctic.jpg'),
    thumbMedium: require('./images/collection/arctic.jpg'),
  },
  {
    label: 'Ash',
    value: 'ash',
    slug: 'ash',
    sub: 'MAG-6031',
    thumbSmall: require('./images/collection/small/ash.jpg'),
    thumbMedium: require('./images/collection/ash.jpg'),
  },
  {
    label: 'Blackcave',
    value: 'blackcave',
    slug: 'blackcave',
    sub: 'MAG-6032',
    thumbSmall: require('./images/collection/small/blackcave.jpg'),
    thumbMedium: require('./images/collection/blackcave.jpg'),
  },
  {
    label: 'Grizzly',
    value: 'grizzly',
    slug: 'grizzly',
    sub: 'MAG-6018',
    thumbSmall: require('./images/collection/small/grizzly.jpg'),
    thumbMedium: require('./images/collection/grizzly.jpg'),
  },
  {
    label: 'Fjord',
    value: 'fjord',
    slug: 'fjord',
    sub: 'MAG-6021',
    thumbSmall: require('./images/collection/small/fjord.jpg'),
    thumbMedium: require('./images/collection/fjord.jpg'),
  },
  {
    label: 'Hive',
    value: 'hive',
    slug: 'hive',
    sub: 'MAG-6029',
    thumbSmall: require('./images/collection/small/hive.jpg'),
    thumbMedium: require('./images/collection/hive.jpg'),
  },
  {
    label: 'Kyanite',
    value: 'kyanite',
    slug: 'kyanite',
    sub: 'MAG-6023',
    thumbSmall: require('./images/collection/small/kyanite.jpg'),
    thumbMedium: require('./images/collection/kyanite.jpg'),
  },
  {
    label: 'Maple',
    value: 'maple',
    slug: 'maple',
    sub: 'MAG-6026',
    thumbSmall: require('./images/collection/small/maple.jpg'),
    thumbMedium: require('./images/collection/maple.jpg'),
  },
  {
    label: 'Maui',
    value: 'maui',
    slug: 'maui',
    sub: 'MAG-6020',
    thumbSmall: require('./images/collection/small/maui.jpg'),
    thumbMedium: require('./images/collection/maui.jpg'),
  },
  {
    label: 'Minuit',
    value: 'minuit',
    slug: 'minuit',
    sub: 'MAG-6028',
    thumbSmall: require('./images/collection/small/minuit.jpg'),
    thumbMedium: require('./images/collection/minuit.jpg'),
  },
  {
    label: 'Monochrome',
    value: 'monochrome',
    slug: 'monochrome',
    sub: 'MAG-6031',
    thumbSmall: require('./images/collection/small/monochrome.jpg'),
    thumbMedium: require('./images/collection/monochrome.jpg'),
  },
  {
    label: 'Oak',
    value: 'oak',
    slug: 'oak',
    sub: 'MAG-6018',
    thumbSmall: require('./images/collection/small/oak.jpg'),
    thumbMedium: require('./images/collection/oak.jpg'),
  },
  {
    label: 'Svalbard',
    value: 'svalbard',
    slug: 'svalbard',
    sub: 'MAG-6022',
    thumbSmall: require('./images/collection/small/svalbard.jpg'),
    thumbMedium: require('./images/collection/svalbard.jpg'),
  },
  {
    label: 'Tahoe',
    value: 'tahoe',
    slug: 'tahoe',
    sub: 'MAG-6027',
    thumbSmall: require('./images/collection/small/tahoe.jpg'),
    thumbMedium: require('./images/collection/tahoe.jpg'),
  },

]