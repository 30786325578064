import React from 'react';
import './styles/paragraph.scss';
import {FormattedMessage} from "react-intl"

export default function Paragraph({className}) {
  return (
      <div className={`paragraph ${className}`}>
        <h4 className="text-uppercase">
          <FormattedMessage
              id="Paragraph.title"
              defaultMessage="THE NEW ERA IN TEXTILE LOOKING COATED FABRICS"/>
        </h4>
        <p>
          <FormattedMessage
              id="Paragraph.subtitle"
              defaultMessage="Immersed through a colour experience, Spradling<sup>®</sup> has created a new Maglia<sup>™</sup> colour range. We want you to blend with nature, we want you to go back to the essential. The essence of inspiring, creating, feeling, building and caring."
              values={{
                sup: (...chunks) => <sup>{chunks}</sup>
              }}/>
        </p>
      </div>
  )
}