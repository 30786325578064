import React from 'react';
import {Col, Row} from 'reactstrap';
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import axios from 'axios';
import './styles/signup.scss'
import {FormattedMessage} from "react-intl"

export default function Signup() {
  const initialValues = {
    name: "",
    email: ""
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("This field is required"),
    email: Yup.string().required("This field is required"),
  });

  function onSubmit(values, {setSubmitting}) {
    setSubmitting(true);
    values = {...values, updateEnabled: false, listIds: [112]};
    //console.log(values);
    const promise = axios.post("https://api.sendinblue.com/v3/contacts", values, {
      headers: {
        'accept': "application/json",
        'content-type': "application/json",
        'api-key': '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'
      }
    });
    promise.then(response => {
      setSubmitting(false)
    });
    promise.catch(error => {
      if (error.response.data.code === "duplicate_parameter") {
        promise.finally();
      }
      setSubmitting(false)
    });
  }


  return (
      <div className="form-group">
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>
          {
            ({errors, isSubmitting}) => (
                <Form noValidate>
                  <h6>
                    <FormattedMessage
                        id="Signup.title"
                        defaultMessage="Sign Up Now"/>
                  </h6>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className="form-group">
                        <FormattedMessage
                            id="Signup.placeholderName"
                            defaultMessage="Name">
                          {
                            text =>
                                <Field
                                    id="name"
                                    name="name"
                                    type="text"
                                    placeholder={text}
                                    className="input"/>
                          }
                        </FormattedMessage>
                        <ErrorMessage name="name"/>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className="form-group">
                        <FormattedMessage
                            id="Signup.placeholderEmail"
                            defaultMessage="Email">
                          {
                            text =>
                                <Field
                                    id="email"
                                    name="email"
                                    type="email"
                                    placeholder={text}
                                    className="input"/>
                          }
                        </FormattedMessage>
                        <ErrorMessage name="email"/>
                      </div>
                    </Col>
                  </Row>
                  <button type="submit" disabled={isSubmitting}
                          className="btn btn-primary rounded-0 mt-2 w-100">
                    {
                      isSubmitting
                          ?
                          <FormattedMessage
                              id="Signup.sending"
                              defaultMessage="Sending..."/>
                          :
                          <FormattedMessage
                              id="Signup.btnLabel"
                              defaultMessage="SIGN UP NOW"/>
                    }
                  </button>
                </Form>
            )
          }
        </Formik>
      </div>
  )
}
