import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import Signup from './components/Signup';
import spradlingLogo from './img/spradling_logo_gray.svg';
import './style/footer.scss'
import logo from "./img/DM-2021_1-logo.svg"
import {FormattedMessage} from "react-intl"

export default function Footer() {
  return (
      <div className="Footer mt-4">
        <div className="bg-gray">
          <div className="container">
            <div className="row">
              <div className="col-md-3 py-3">
                <div className="form-group">
                  <h6>
                    <FormattedMessage
                        id="Footer.linksTitle"
                        defaultMessage="Helpful Links"/>
                  </h6>
                  <ul>
                    <li>
                      <FormattedMessage
                          id="Footer.linkPrivacy"
                          defaultMessage="Privacy Policy"/>
                    </li>
                    <li>
                      <FormattedMessage
                          id="Footer.linkCookies"
                          defaultMessage="Cookies Policy"/>
                    </li>
                    <li>
                      <FormattedMessage
                          id="Footer.linkLegal"
                          defaultMessage="Legal Warning"/>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 py-3">
                <div className="form-group desktop">
                  <h6>
                    <FormattedMessage
                        id="Footer.followUs"
                        defaultMessage="Follow Us"/>
                  </h6>
                  <ul>
                    <li>
                      <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/SpradlingEurope">
                        Facebook
                      </a>
                    </li>
                    <li>
                      <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/spradlingeurope/">
                        Instagram
                      </a>
                    </li>
                    <li>
                      <a target="_blank" rel="noopener noreferrer"
                         href="https://www.linkedin.com/company/spradling-europe">
                        Linkedin
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3">
                <div className="footer-award">
                  <div className="text-center">
                    <img src={logo} alt=""/>
                    <p className="text-center text-uppercase mt-4 mb-0">
                      <FormattedMessage
                          id="Footer.winner"
                          defaultMessage="Winner"/>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 py-3">
                <Signup/>
              </div>
            </div>
            <div className="followus">
              <h6>
                <FormattedMessage
                    id="Footer.followUs"
                    defaultMessage="Follow Us"/>
              </h6>
              <ul>
                <li>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/SpradlingEurope">
                    Facebook
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/spradlingeurope/">
                    Instagram
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/spradling-europe">
                    Linkedin
                  </a>
                </li>
              </ul>
            </div>

          </div>
        </div>

        <div className="container">
          <div className="footer-logo my-3">
            <div className="d-flex align-items-end">
              <img src={spradlingLogo} style={{maxHeight: '25px'}} alt="..."/>
              <span className="small">&copy; 2020 Spradling Europe</span>
            </div>

            <div className="desktop">
              <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/SpradlingEurope">
                <FontAwesomeIcon icon={['fab', 'facebook-f']} size="lg" color="gray" className="mr-3"/>
              </a>
              <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/spradlingeurope/">
                <FontAwesomeIcon icon={['fab', 'instagram']} size="lg" color="gray" className="mr-3"/>
              </a>
              <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/spradling-europe">
                <FontAwesomeIcon icon={['fab', 'linkedin']} size="lg" color="gray"/>
              </a>
            </div>
          </div>
        </div>

      </div>
  )
}
