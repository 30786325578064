import React from 'react';
import ecosense from './img/ecosense.svg';
import permablok from './img/PERMABLOK.svg';
import phathalate from './img/PHTHALATE_2.svg';
import {Divider} from '../Custom';
import {Container, Row, Col} from 'reactstrap'
import './detail.scss';
import {FormattedMessage} from "react-intl"

const items = [
  [
    <FormattedMessage id="Detail.itemComposition" defaultMessage="Composition"/>,
    <FormattedMessage id="Detail.itemTopcoat" defaultMessage="Topcoat: Vinyl"/>
  ],
  [
    <FormattedMessage id="Detail.itemBacking" defaultMessage="Backing"/>,
    <FormattedMessage id="Detail.item100Poly" defaultMessage="100% Polyester Hi-Loft"/>
  ],
  [
    <FormattedMessage id="Detail.itemWeight" defaultMessage="Weight"/>,
    <FormattedMessage id="Detail.item680" defaultMessage="680 gr/m2"/>
  ],
  [
    <FormattedMessage id="Detail.itemWidth" defaultMessage="Width"/>,
    <FormattedMessage id="Detail.itemMin" defaultMessage="min. 137 cm"/>
  ],
  [
    <FormattedMessage id="Detail.itemRoll" defaultMessage="Roll size"/>,
    <FormattedMessage id="Detail.itemMaomeno" defaultMessage="+/- 30m"/>
  ],
  [
    <FormattedMessage id="Detail.itemAbrasion" defaultMessage="Abrasion"/>,
    <FormattedMessage id="Detail.item100" defaultMessage="100.000 cycles (MARTINDALE)"/>
  ],
  [
    <FormattedMessage id="Detail.itemCold" defaultMessage="Cold Crack"/>,
    <FormattedMessage id="Detail.item29" defaultMessage="-29°C"/>
  ],
  [
    <FormattedMessage id="Detail.itemUV" defaultMessage="UV-Resistence"/>,
    <span>
      <FormattedMessage id="Detail.b02" defaultMessage="ISO 105 B02:>7/8 (Blue Wool Scale)"/>
      <br/>
      <FormattedMessage id="Detail.b04" defaultMessage="ISO 104 B04:>4/5 (Grey Scale)"/>
    </span>
  ],
]

const other = [
  [
    <FormattedMessage id="Detail.otherTitle" defaultMessage="Flame retardancy*"/>,
    [
      [
        'EU',
        <FormattedMessage id="Detail.EN" defaultMessage="EN 1021 Part 1 & 2"/>
      ],
      [
        'EU',
        <FormattedMessage id="Detail.ECE" defaultMessage="ECE R 118.02Anx 6,7 & 8"/>
      ],
      [
        'EU',
        <FormattedMessage id="Detail.MED" defaultMessage="(MED) Marine Equipment Directive"/>
      ],
      [
        'EU',
        <FormattedMessage id="Detail.IMO" defaultMessage="IMO 2010 FTP Part 8"/>
      ],
      [
        'EU',
        <FormattedMessage
            id="Detail.71"
            defaultMessage="71-2-2006+AI:2007{break}Safety of Toys - part 2."
            values={{
              break: <br/>
            }}/>
      ],
      [
        'DE',
        <FormattedMessage id="Detail.DIN4102B2" defaultMessage="DIN4102B2"/>
      ],
      [
        'IT',
        <FormattedMessage id="Detail.UNI" defaultMessage="UNI 9175 Classe I.IM"/>
      ],
      [
        'ES',
        <FormattedMessage id="Detail.UNE" defaultMessage="UNE 23.272-90 IR/M.2"/>
      ],
      [
        'FR',
        <FormattedMessage id="Detail.NFP" defaultMessage="NFP 92-503 / M2"/>
      ],
      [
        'AT',
        <FormattedMessage id="Detail.ONORM" defaultMessage="ONORM B 3825. Gruppe I"/>
      ],
      [
        'AT',
        <FormattedMessage id="Detail.ONORMA3800" defaultMessage="ONORMA3800 Teil I"/>
      ],
      [
        'US',
        <FormattedMessage id="Detail.FMVSS302" defaultMessage="FMVSS302"/>
      ],
      [
        'US',
        <FormattedMessage id="Detail.CALTB" defaultMessage="CALTB 117-2013,Sec.E"/>
      ],
      [
        'US',
        <FormattedMessage id="Detail.FAR25" defaultMessage="FAR25/853"/>
      ],
    ]
  ],
  [
    <FormattedMessage id="Detail.finishes" defaultMessage="FINISHES AND TREATMENTS"/>,
    permablok,
    phathalate
  ],
  [
    <FormattedMessage id="Detail.Virustatic" defaultMessage="Virustatic Properties"/>,
    <FormattedMessage id="Detail.Coated"
                      defaultMessage="Coated Fabrics treated with PERMABLOK3<sup>®</sup> are proven to reduce the presence of more than 90% of Coronavirus’s** within one hour of contact."
                      values={{
                        sup: (...chunks) => <sup>{chunks}</sup>
                      }}/>,
  ],
  [
    <FormattedMessage id="Detail.Bacteriostatic" defaultMessage="Bacteriostatic Properties"/>,
    <FormattedMessage id="Detail.ISO22196" defaultMessage="ISO 22196:2011 Value of antibacterial*** activity R > 2"/>,
  ],
  [
    <FormattedMessage id="Detail.sustainability" defaultMessage="SUSTAINABILITY"/>,
    [
      ecosense,
      <FormattedMessage id="Detail.ISO14001" defaultMessage="ISO 14001 Environmental Managment"/>,
      <FormattedMessage id="Detail.Carbon" defaultMessage="Carbon Neutral"/>,
      <FormattedMessage id="Detail.REACH" defaultMessage="REACH"/>,
      <FormattedMessage id="Detail.RoHS" defaultMessage="RoHS"/>,
    ]
  ],
  [
    <span><FormattedMessage
        id="Detail.warranty"
        defaultMessage="WARRANTY"/><sup>3</sup></span>,
    <FormattedMessage
        id="Detail.5Years"
        defaultMessage="5 YEARS"/>
  ]
]

export default function Detail() {


  return (
      <div className="Detail mt-5">
        <Container>
          <Row>
            <Col>
              <h4 className="text-primary">
                <FormattedMessage
                    id="Detail.characteristics"
                    defaultMessage="CHARACTERISTICS"/>
              </h4>
            </Col>
          </Row>
          <Divider/>

          <Row>
            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
              <div className="w-100 d-flex align-items-center">
                <h5 className="text-primary">
                  {items[0][0]}:
                </h5>
              </div>
            </Col>
            <Col xs={7} sm={7} md={7} lg={7} xl={7}>
              <h6 className="description">
                {items[0][1]}
              </h6>
            </Col>
          </Row>
          <Divider/>

          <Row>
            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
              <div className="w-100 d-flex align-items-center">
                <h5 className="text-primary">
                  {items[1][0]}:
                </h5>
              </div>
            </Col>
            <Col xs={7} sm={7} md={7} lg={7} xl={7}>
              <h6 className="description">
                {items[1][1]}<sup>2</sup>&trade;
              </h6>
            </Col>
          </Row>
          <Divider/>

          <Row>
            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
              <div className="w-100 d-flex align-items-center">
                <h5 className="text-primary">
                  {items[2][0]}:
                </h5>
              </div>
            </Col>
            <Col xs={7} sm={7} md={7} lg={7} xl={7}>
              <h6 className="description">
                {items[2][1]}
              </h6>
            </Col>
          </Row>
          <Divider/>

          <Row>
            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
              <div className="w-100 d-flex align-items-center">
                <h5 className="text-primary">
                  {items[3][0]}:
                </h5>
              </div>
            </Col>
            <Col xs={7} sm={7} md={7} lg={7} xl={7}>
              <h6 className="description">
                {items[3][1]}
              </h6>
            </Col>
          </Row>
          <Divider/>

          <Row>
            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
              <div className="w-100 d-flex align-items-center">
                <h5 className="text-primary">
                  {items[4][0]}:
                </h5>
              </div>
            </Col>
            <Col xs={7} sm={7} md={7} lg={7} xl={7}>
              <h6 className="description">
                {items[4][1]}
              </h6>
            </Col>
          </Row>
          <Divider/>


          <Row>
            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
              <div className="w-100 d-flex align-items-center">
                <h5 className="text-primary">
                  {items[5][0]}:
                </h5>
              </div>
            </Col>
            <Col xs={7} sm={7} md={7} lg={7} xl={7}>
              <h6 className="description">
                {'>'}{items[5][1]}
              </h6>
            </Col>
          </Row>
          <Divider/>

          <Row>
            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
              <div className="w-100 d-flex align-items-center">
                <h5 className="text-primary">
                  {items[6][0]}:
                </h5>
              </div>
            </Col>
            <Col xs={7} sm={7} md={7} lg={7} xl={7}>
              <h6 className="description">
                {items[6][1]}
              </h6>
            </Col>
          </Row>
          <Divider/>

          <Row>
            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
              <div className="w-100 d-flex align-items-center">
                <h5 className="text-primary">
                  {items[7][0]}:
                </h5>
              </div>
            </Col>
            <Col xs={7} sm={7} md={7} lg={7} xl={7}>
              <h6 className="description">
                {items[7][1]}
              </h6>
            </Col>
          </Row>
          <Divider/>

          <Row>
            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
              <div className="w-100 d-flex align-items-center">
                <h5 className="text-primary">
                  {other[0][0]}:
                </h5>
              </div>
            </Col>
            <Col xs={7} sm={7} md={7} lg={7} xl={7}>
              <Container className="px-0">
                {
                  other[0][1].map((el) =>
                      <Row>
                        <Col xs={1} sm={1} md={2}>
                          <h6 className="description">{el[0]}</h6>
                        </Col>
                        <Col xs={9} sm={9} md={10}>
                          <h6 className="description">{el[1]}</h6>
                        </Col>
                      </Row>
                  )
                }
              </Container>
            </Col>
          </Row>
          <p className="small">
            *<FormattedMessage
              id="Detail.thisTerm"
              defaultMessage="This term and any corresponding data refer to the typical performance in the specific tests indicated and should not be construed to imply the behavior of this or any other material under actual fire conditions."/>
          </p>
          <Divider/>

          <Row>
            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
              <div className="w-100 d-flex align-items-center">
                <h5 className="text-primary">
                  {other[1][0]}
                </h5>
              </div>
            </Col>
            <Col xs={7} sm={7} md={7} lg={7} xl={7}>
              <div className="py-1 d-flex" style={{maxHeight: "100px"}}>
                <img src={other[1][1]} alt="permablok" className="images"/>
                <div style={{width: '7px'}}/>
                <img src={other[1][2]} alt="phthalate" className="images"/>
              </div>
            </Col>
          </Row>
          <Divider/>

          <Row>
            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
              <h5 className="text-primary">
                {other[2][0]}
              </h5>
            </Col>
            <Col xs={7} sm={7} md={7} lg={7} xl={7}>
              <h6 className="description">
                ISO 18184:2019':
              </h6>
              <h6 className="description description--light">
                {other[2][1]}
              </h6>
              <h6 className="description">
                ISO 21702:2019<sup>2</sup>:
              </h6>
              <h6 className="description description--light">
                <FormattedMessage
                    id="Detail.allTheCoated"
                    defaultMessage="All the Coated fabrics treated with PERMABLOK<sup>®</sup> are capable of reducing the Coronavirus** activity in 99,9% within 24 hours of exposure under the surface."
                    values={{
                      sup: (...chunks) => <sup>{chunks}</sup>
                    }}/>
              </h6>
            </Col>
          </Row>
          <Divider/>

          <Row>
            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
              <h5 className="text-primary">
                {other[3][0]}
              </h5>
            </Col>
            <Col xs={7} sm={7} md={7} lg={7} xl={7}>
              <h6 className="description">
                {other[3][1]}
              </h6>
            </Col>
          </Row>
          <Divider/>

          <Row>
            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
              <h5 className="text-primary">
                {other[4][0]}
              </h5>
            </Col>
            <Col xs={7} sm={7} md={7} lg={7} xl={7}>
              {
                other[4][1].map((el, i) => i === 0
                    ? <img key={i} src={el} alt="ecosense" className="images"/>
                    : <h6 key={i} className="description"> {el} </h6>
                )
              }
            </Col>
          </Row>
          <Divider/>
          <Row>
            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
              <h5 className="text-primary">
                {other[5][0]}
              </h5>
            </Col>
            <Col xs={7} sm={7} md={7} lg={7} xl={7}>
              <h6 className="description">
                {
                  other[5][1]
                }
              </h6>
            </Col>
          </Row>
          <p className="small">
            1. <FormattedMessage
              id="Detail.disclaimer1"
              defaultMessage="Determination of antiviral activity of textile products"/><br/>
            2. <FormattedMessage
              id="Detail.disclaimer2"
              defaultMessage="Measurement of antiviral activity on plastics and other non-porous surfaces."/><br/>
            **<FormattedMessage
              id="Detail.disclaimer3"
              defaultMessage="Testing was conducted with material expoused to Feline Coronavirus, strain Munich (FIPV)"/><br/>
            ***<FormattedMessage
              id="Detail.disclaimer4"
              defaultMessage="Testing was conducted with material expoused to Staphylococcus aureus (MRSA), Escherichia coli, and Klebsiella pneumoniae. "/><br/>
            3. <FormattedMessage
              id="Detail.disclaimer5"
              defaultMessage="Terms and conditions apply. Warranty conditions are displayed on our website."/>
          </p>
          <Divider/>

        </Container>
      </div>
  )
}
