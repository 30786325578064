import React from 'react';
import { Field } from 'formik';
import colors from '../../../../assets/colors';
import Select from 'react-select';

export default function CustomSelect() {
  const [isDisabled, setIsDisabled] = React.useState(false);

  const renderSelect = ({ field, form: { setFieldValue } }) => {
    const handleChange = (selected) => {
      //console.log(selected)
      let names = "";
      if (selected) {
        //console.log(selected.length < 11);
        if (selected.length < 11) {
          setIsDisabled(false);
          names = selected.map(option => option.label).join(",");
          if (selected.length === 10) {
            setIsDisabled(true);
          }
        }
      }
      setFieldValue(field.name, names);
    }
    return (
      <Select
        isMulti
        className="basic-multi-select"
        name="colors"
        isDisabled={isDisabled}
        options={colors}
        onChange={handleChange}
      />
    )
  }

  return (
    <Field
      name="colours"
      render={renderSelect}
    />
  )
}
