import React from 'react'
import ReactDOM from 'react-dom'
import {Route, Redirect, BrowserRouter as Router} from 'react-router-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { ApolloProvider } from '@apollo/client'
import client from './api'

ReactDOM.render(
  <ApolloProvider client={client}>
    <Router>
      <Route
          exact
          path="/">
        <Redirect to={`/en`}/>
      </Route>
      <Route
          exact
          path="/:lang">
        <App/>
      </Route>
      <Route
          path="/:lang/detail/:slug">
        <App detail/>
      </Route>
    </Router>
  </ApolloProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();