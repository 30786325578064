import React from 'react';
import micro from './img/micro_2.jpg';
import logoPermablok from './img/logo_permablok.svg';
import {FormattedMessage} from "react-intl"

export default function Permablok() {
  return (
      <div className="Permablok mt-5">
        <div className="description">
          <div className="container-images">
            <img className="img-fluid" src={micro} alt="micro"/>
            <div className="image-small">
              <img className="img-fluid" src={logoPermablok} alt="ecosense"/>
            </div>
          </div>

          <h4 className="text-center">
            Permablok<sup>3&reg;</sup>
          </h4>
          <p>
            <FormattedMessage
                id="Permablok.content"
                defaultMessage="Thinking of the most frequent problems encountered in healthcare, hospitality, mass transportation and public enviroments, we engineered PERMABLOK<sup>3&reg;</sup>, a resilient and effective permanent defence that ensures superior protection against virus, bacteria, stains and abrasion! Its the only protective coating in the market certified as ISO 18184, ISO 21702 and ISO 22196."
                values={{
                  sup: (...chunks) => <sup>{chunks}</sup>
                }}/>
          </p>
        </div>
      </div>
  )
}
