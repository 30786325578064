const emailsCountries = {
  "Afghanistan": "info.de@spradling.group",
  "Albania": "info.de@spradling.group",
  "Algeria": "info.es@spradling.group",
  "American Samoa": "info.es@spradling.group",
  "Andorra": "info.es@spradling.group",
  "Angola": "info.es@spradling.group",
  "Anguilla": "info.es@spradling.group",
  "Antarctica": "info.es@spradling.group",
  "Antigua and Barbuda": "info.es@spradling.group",
  "Argentina": "info.es@spradling.group",
  "Armenia": "info.de@spradling.group",
  "Aruba": "info.es@spradling.group",
  "Australia": "info.es@spradling.group",
  "Austria": "info.de@spradling.group",
  "Azerbaijan": "info.de@spradling.group",
  "Bahamas": "info.es@spradling.group",
  "Bahrain": "info.de@spradling.group",
  "Bangladesh": "info.de@spradling.group",
  "Barbados": "info.es@spradling.group",
  "Belarus": "info.de@spradling.group",
  "Belgium": "info.de@spradling.group",
  "Belize": "info.es@spradling.group",
  "Benin": "info.es@spradling.group",
  "Bermuda": "info.es@spradling.group",
  "Bhutan": "info.de@spradling.group",
  "Bolivia": "info.es@spradling.group",
  "Bosnia and Herzegowina": "info.de@spradling.group",
  "Botswana": "info.es@spradling.group",
  "Bouvet Island": "info.es@spradling.group",
  "Brazil": "info.es@spradling.group",
  "British Indian Ocean Territory": "info.es@spradling.group",
  "Brunei Darussalam": "info.de@spradling.group",
  "Bulgaria": "info.de@spradling.group",
  "Burkina Faso": "info.es@spradling.group",
  "Burundi": "info.es@spradling.group",
  "Cambodia": "info.de@spradling.group",
  "Cameroon": "info.es@spradling.group",
  "Canada": "info.es@spradling.group",
  "Cape Verde": "info.es@spradling.group",
  "Cayman Islands": "info.es@spradling.group",
  "Central African Republic": "info.es@spradling.group",
  "Chad": "info.es@spradling.group",
  "Chile": "info.es@spradling.group",
  "China": "info.de@spradling.group",
  "Christmas Island": "info.es@spradling.group",
  "Cocos (Keeling) Islands": "info.es@spradling.group",
  "Colombia": "info.es@spradling.group",
  "Comoros": "info.es@spradling.group",
  "Congo, Democratic Republic Of (was Zaire)": "info.es@spradling.group",
  "Congo, People's Republic of": "info.es@spradling.group",
  "Cook Islands": "info.es@spradling.group",
  "Costa Rica": "info.es@spradling.group",
  "Cote D'ivoire": "info.es@spradling.group",
  "Croatia (Hrvatska)": "info.de@spradling.group",
  "Cuba": "info.es@spradling.group",
  "Cyprus": "info.de@spradling.group",
  "Czech Republic": "info.de@spradling.group",
  "Denmark": "info.de@spradling.group",
  "Djibouti": "info.es@spradling.group",
  "Dominica": "info.es@spradling.group",
  "Dominican Republic": "info.es@spradling.group",
  "East Timor": "info.de@spradling.group",
  "Ecuador": "info.es@spradling.group",
  "Egypt": "info.es@spradling.group",
  "El Salvador": "info.es@spradling.group",
  "Equatorial Guinea": "info.es@spradling.group",
  "Eritrea": "info.es@spradling.group",
  "Estonia": "info.de@spradling.group",
  "Ethiopia": "info.es@spradling.group",
  "Falkland Islands (Malvinas)": "info.es@spradling.group",
  "Faroe Islands": "info.de@spradling.group",
  "Fiji": "info.es@spradling.group",
  "Finland": "info.de@spradling.group",
  "France": "info.es@spradling.group",
  "France, Metropolitan": "info.es@spradling.group",
  "French Guiana": "info.es@spradling.group",
  "French Polynesia": "info.es@spradling.group",
  "French Southern Territories": "info.es@spradling.group",
  "Gabon": "info.es@spradling.group",
  "Gambia": "info.es@spradling.group",
  "Georgia": "info.es@spradling.group",
  "Germany": "info.de@spradling.group",
  "Ghana": "info.es@spradling.group",
  "Gibraltar": "info.es@spradling.group",
  "Greece": "info.de@spradling.group",
  "Greenland": "info.de@spradling.group",
  "Grenada": "info.es@spradling.group",
  "Guadeloupe": "info.es@spradling.group",
  "Guam": "info.es@spradling.group",
  "Guatemala": "info.es@spradling.group",
  "Guinea": "info.es@spradling.group",
  "Guinea-Bissau": "info.es@spradling.group",
  "Guyana": "info.es@spradling.group",
  "Haiti": "info.es@spradling.group",
  "Heard and Mc Donald Islands": "info.es@spradling.group",
  "Honduras": "info.es@spradling.group",
  "Hong Kong": "info.de@spradling.group",
  "Hungary": "info.de@spradling.group",
  "Iceland": "info.de@spradling.group",
  "India": "info.de@spradling.group",
  "Indonesia": "info.de@spradling.group",
  "Iran (Islamic Republic of)": "info.de@spradling.group",
  "Iraq": "info.de@spradling.group",
  "Ireland": "info.uk@spradling.group",
  "Israel": "info.de@spradling.group",
  "Italy": "info.es@spradling.group",
  "Jamaica": "info.es@spradling.group",
  "Japan": "info.de@spradling.group",
  "Jordan": "info.de@spradling.group",
  "Kazakhstan": "info.de@spradling.group",
  "Kenya": "info.es@spradling.group",
  "Kiribati": "info.es@spradling.group",
  "Korea, Democratic People's Republic of": "info.de@spradling.group",
  "Korea, Republic of": "info.de@spradling.group",
  "Kuwait": "info.de@spradling.group",
  "Kyrgyzstan": "info.de@spradling.group",
  "Lao People's Democratic Republic": "info.de@spradling.group",
  "Latvia": "info.de@spradling.group",
  "Lebanon": "info.de@spradling.group",
  "Lesotho": "info.es@spradling.group",
  "Liberia": "info.es@spradling.group",
  "Libya": "info.es@spradling.group",
  "Liechtenstein": "info.de@spradling.group",
  "Lithuania": "info.de@spradling.group",
  "Luxembourg": "info.de@spradling.group",
  "Macau": "info.de@spradling.group",
  "Macedonia": "info.de@spradling.group",
  "Madagascar": "info.es@spradling.group",
  "Malawi": "info.es@spradling.group",
  "Malaysia": "info.de@spradling.group",
  "Maldives": "info.de@spradling.group",
  "Mali": "info.es@spradling.group",
  "Malta": "info.de@spradling.group",
  "Marshall Islands": "info.es@spradling.group",
  "Martinique": "info.es@spradling.group",
  "Mauritania": "info.es@spradling.group",
  "Mauritius": "info.es@spradling.group",
  "Mayotte": "info.es@spradling.group",
  "Mexico": "info.es@spradling.group",
  "Micronesia, Federated States of": "info.es@spradling.group",
  "Moldova, Republic of": "info.de@spradling.group",
  "Monaco": "info.es@spradling.group",
  "Mongolia": "info.de@spradling.group",
  "Montserrat": "info.es@spradling.group",
  "Morocco": "info.es@spradling.group",
  "Mozambique": "info.es@spradling.group",
  "Myanmar": "info.de@spradling.group",
  "Namibia": "info.es@spradling.group",
  "Nauru": "info.es@spradling.group",
  "Nepal": "info.de@spradling.group",
  "Netherlands": "info.de@spradling.group",
  "Netherlands Antilles": "info.es@spradling.group",
  "New Caledonia": "info.es@spradling.group",
  "New Zealand": "info.es@spradling.group",
  "Nicaragua": "info.es@spradling.group",
  "Niger": "info.es@spradling.group",
  "Nigeria": "info.es@spradling.group",
  "Niue": "info.es@spradling.group",
  "Norfolk Island": "info.es@spradling.group",
  "Northern Mariana Islands": "info.es@spradling.group",
  "Norway": "info.de@spradling.group",
  "Oman": "info.de@spradling.group",
  "Pakistan": "info.de@spradling.group",
  "Palau": "info.es@spradling.group",
  "Panama": "info.es@spradling.group",
  "Papua New Guinea": "info.de@spradling.group",
  "Paraguay": "info.es@spradling.group",
  "Peru": "info.es@spradling.group",
  "Philippines": "info.de@spradling.group",
  "Pitcairn": "info.es@spradling.group",
  "Poland": "info.de@spradling.group",
  "Portugal": "info.es@spradling.group",
  "Puerto Rico": "info.es@spradling.group",
  "Qatar": "info.de@spradling.group",
  "Reunion": "info.es@spradling.group",
  "Romania": "info.de@spradling.group",
  "Russian Federation": "info.de@spradling.group",
  "Rwanda": "info.es@spradling.group",
  "Saint Kitts and Nevis": "info.es@spradling.group",
  "Saint Lucia": "info.es@spradling.group",
  "Saint Vincent and The Grenadines": "info.es@spradling.group",
  "Samoa": "info.es@spradling.group",
  "San Marino": "info.es@spradling.group",
  "Sao Tome and Principe": "info.es@spradling.group",
  "Saudi Arabia": "info.de@spradling.group",
  "Senegal": "info.es@spradling.group",
  "Seychelles": "info.de@spradling.group",
  "Sierra Leone": "info.es@spradling.group",
  "Singapore": "info.de@spradling.group",
  "Slovakia (Slovak Republic)": "info.de@spradling.group",
  "Slovenia": "info.de@spradling.group",
  "Solomon Islands": "info.es@spradling.group",
  "Somalia": "info.es@spradling.group",
  "South Africa": "info.es@spradling.group",
  "South Georgia and The South Sandwich Islands": "info.es@spradling.group",
  "Spain": "info.es@spradling.group",
  "Sri Lanka": "info.de@spradling.group",
  "St. Helena": "info.es@spradling.group",
  "St. Pierre and Miquelon": "info.es@spradling.group",
  "Sudan": "info.es@spradling.group",
  "Suriname": "info.es@spradling.group",
  "Svalbard and Jan Mayen Islands": "info.de@spradling.group",
  "Swaziland": "info.es@spradling.group",
  "Sweden": "info.de@spradling.group",
  "Switzerland": "info.de@spradling.group",
  "Syrian Arab Republic": "info.de@spradling.group",
  "Taiwan": "info.de@spradling.group",
  "Tajikistan": "info.de@spradling.group",
  "Tanzania, United Republic Of": "info.es@spradling.group",
  "Thailand": "info.de@spradling.group",
  "Togo": "info.es@spradling.group",
  "Tokelau": "info.es@spradling.group",
  "Tonga": "info.es@spradling.group",
  "Trinidad and Tobago": "info.es@spradling.group",
  "Tunisia": "info.es@spradling.group",
  "Turkey": "info.de@spradling.group",
  "Turkmenistan": "info.de@spradling.group",
  "Turks and Caicos Islands": "info.es@spradling.group",
  "Tuvalu": "info.es@spradling.group",
  "Uganda": "info.es@spradling.group",
  "Ukraine": "info.de@spradling.group",
  "United Arab Emirates": "info.de@spradling.group",
  "United Kingdom": "info.uk@spradling.group",
  "United States": "info.es@spradling.group",
  "United States Minor Outlying Islands": "info.es@spradling.group",
  "Uruguay": "info.es@spradling.group",
  "Uzbekistan": "info.de@spradling.group",
  "Vanuatu": "info.es@spradling.group",
  "Vatican City State (Holy City)": "info.es@spradling.group",
  "Venezuela": "info.es@spradling.group",
  "Vietnam": "info.de@spradling.group",
  "Virgin Islands (British)": "info.uk@spradling.group",
  "Virgin Islands (U.S.)": "info.es@spradling.group",
  "Wallis and Futuna Islands": "info.es@spradling.group",
  "Yemen": "info.de@spradling.group",
  "Zambia": "info.es@spradling.group",
  "Zimbabwe": "info.es@spradling.group",
}

export default emailsCountries