import React from "react";
import {useLocation, useRouteMatch} from "react-router-dom";
import VideoPreview from './components/VideoPreview'
import HeroLinks from "./components/HeroLinks"
import ColorsSlider from "./components/ColorsSlider"
import Paragraph from "./components/Paragraph"
import BackgroundImage, {getColorBySlug} from "./components/BackgroundImage";
import './styles/Hero.scss';
import ScrollDown from "../ScrollDown"
import {FormattedMessage} from "react-intl"

export default function Hero() {
  const location = useLocation()
  const match = useRouteMatch()

  return (
      <BackgroundImage classes="hero" slug={match.params.slug}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-7 col-xl-8 order-md-1">
              <HeroLinks/>
              <BackgroundImage classes="hero-bg" slug={match.params.slug}>
                {
                  !match.params.slug &&
                  <div className="hero-video-preview">
                    <VideoPreview/>
                  </div>
                }
              </BackgroundImage>
            </div>
            <div className="col-12 col-md-6 col-lg-5 col-xl-4 pr-md-5 order-md-0">
              <div className="hero-content">
                <div>
                  {
                    !match.params.slug &&
                    <div className="hero-text">
                      <div className="hero-text__title">
                        <h3>
                          Maglia&trade;
                          <br/>
                          <FormattedMessage
                              id="Hero.title"
                              defaultMessage="New Colours"/>
                        </h3>
                      </div>
                      <div className="hero-text__paragraph">
                        <Paragraph className="p-3 px-md-0"/>
                      </div>
                    </div>
                  }

                  {
                    match.params.slug &&
                    <div className="hero-text py-md-5">
                      <div className="hero-text__title" style={{
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        paddingRight: '2rem',
                        color: '#fff',
                        top: 'auto',
                        bottom: '130px'
                      }}>
                        <h1>{getColorBySlug(match.params.slug).label}</h1>
                        <h6>{getColorBySlug(match.params.slug).sub}</h6>
                      </div>
                    </div>
                  }

                  <div className="hero-slider">
                    <ColorsSlider/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {location.pathname === '/' && <ScrollDown/>}
      </BackgroundImage>
  )
}