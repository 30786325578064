import React, { useState } from "react";
import colors from "../../../../assets/colors";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import "./styles/ColorsSlider.scss";
import CarouselImage from "components/Hero/components/ColorsSlider/components/CarouselImage";
import { useRouteMatch } from "react-router-dom";
import { FormattedMessage } from "react-intl";

export default function ColorsSlider() {
  const match = useRouteMatch();
  console.log(match);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, slider] = useKeenSlider({
    slidesPerView: 4,
    spacing: 15,
    controls: window.innerWidth < 768,
  });

  return (
    <div className="colors-slider">
      <h5 className="colors-slider__title text-uppercase">
        <FormattedMessage
          id="ColorsSlider.title"
          defaultMessage="DISCOVER THE NEW COLOUR RANGE"
        />
      </h5>
      <div className="colors-slider__carousel">
        <div ref={sliderRef} className="keen-slider">
          {colors.map((color, i) => (
            <div key={i} className="keen-slider__slide">
              <CarouselImage
                color={color}
                i={i}
                active={match.params && match.params.slug === color.slug}
              />
            </div>
          ))}
        </div>
        {slider && (
          <>
            <ArrowLeft
              onClick={(e) => e.stopPropagation() || slider.prev()}
              disabled={currentSlide === 0}
            />
            <ArrowRight
              onClick={(e) => e.stopPropagation() || slider.next()}
              disabled={currentSlide === slider.details().size - 1}
            />
          </>
        )}
      </div>
    </div>
  );
}

function ArrowLeft(props) {
  const disabeld = props.disabled ? " slider-arrow--disabled" : "";
  return (
    <svg
      onClick={props.onClick}
      className={"slider-arrow slider-arrow--left" + disabeld}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
    </svg>
  );
}

function ArrowRight(props) {
  const disabeld = props.disabled ? " slider-arrow--disabled" : "";
  return (
    <svg
      onClick={props.onClick}
      className={"slider-arrow slider-arrow--right" + disabeld}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
    </svg>
  );
}
