import React from 'react';
import heroBg from './img/bg_2.jpg';
import colors from '../../../../assets/colors';

export function getColorBySlug(slug) {
  return colors.find(color => color.slug === slug)
}

export default function BackgroundImage({classes, slug, children}) {
  console.log(slug)
  return (
      <div className={classes} style={{
        backgroundImage: `url(${!slug ? heroBg : getColorBySlug(slug).thumbMedium})`,
      }}>
        {children}
      </div>
  )
}
