import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "./styles/HeroLinks.scss";
import ModalContext from "../../../SamplingRequest/context";
import SamplingRequest from "../../../SamplingRequest";
import { FormattedMessage } from "react-intl";

export const MODAL_CONTEXT_REQUEST = {
  id: 1,
  title: (
    <FormattedMessage
      id="ModalContext.requestTitle"
      defaultMessage="Request Samples"
    />
  ),
  subtitle: (
    <FormattedMessage
      id="ModalContext.requestSubtitle"
      defaultMessage="request the samples of Maglia"
    />
  ),
  type: "SAMPLING REQUEST",
};
export const MODAL_CONTEXT_DOWNLOAD = {
  id: 2,
  title: (
    <FormattedMessage
      id="ModalContext.downloadTitle"
      defaultMessage="Download Digital Catalog"
    />
  ),
  subtitle: (
    <FormattedMessage
      id="ModalContext.downloadSubtitle"
      defaultMessage="download the digital catalog"
    />
  ),
  type: "DOWNLOAD DIGITAL CATALOGUE",
};
export const MODAL_CONTEXT_CONTACT = {
  id: 3,
  title: (
    <FormattedMessage id="ModalContext.contactTitle" defaultMessage="Contact" />
  ),
  subtitle: (
    <FormattedMessage
      id="ModalContext.contactSubtitle"
      defaultMessage="contact us"
    />
  ),
  type: "CONTACT",
};

export default function HeroLinks({ mobile }) {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [context, setContext] = useState(MODAL_CONTEXT_CONTACT);

  function toggle() {
    setIsOpen(!isOpen);
  }

  return (
    <React.Fragment>
      <div
        className={`hero-links ${mobile ? "mobile" : "web"}`}
        style={{
          paddingRight: location.pathname !== "/" && "0",
        }}
      >
        <ul>
          <li
            onClick={() => {
              setContext(MODAL_CONTEXT_REQUEST);
              toggle();
            }}
          >
            <button type="button">{MODAL_CONTEXT_REQUEST.title}</button>
          </li>
          <li
            onClick={() => {
              setContext(MODAL_CONTEXT_DOWNLOAD);
              toggle();
            }}
          >
            <button type="button">{MODAL_CONTEXT_DOWNLOAD.title}</button>
          </li>
          <li
            onClick={() => {
              setContext(MODAL_CONTEXT_CONTACT);
              toggle();
            }}
          >
            <button type="button">{MODAL_CONTEXT_CONTACT.title}</button>
          </li>
        </ul>
      </div>
      <ModalContext.Provider value={context}>
        <SamplingRequest isOpen={isOpen} toggle={toggle} />
      </ModalContext.Provider>
    </React.Fragment>
  );
}
