import React, {useState} from "react";
import en from './img/video-preview_2.jpg';
import de from './img/Aleman_result.jpg'
import es from './img/Espanol_result.jpg'
import fr from './img/Frances_result.jpg'
import it from './img/Italiano_result.jpg'
import pl from './img/Polaco_result.jpg'
import "./styles/VideoPreview.scss"
import ModalVideo from "components/ModalVideo"
import {useRouteMatch} from "react-router-dom"

export default function VideoPreview() {
  const {params} = useRouteMatch()
  const [isOpen, setIsOpen] = useState(false)

  let image;
  switch (params.lang) {
    case 'es':
      image = es
      break
    case 'en':
      image = en
      break
    case 'fr':
      image = fr
      break
    case 'it':
      image = it
      break
    case 'pl':
      image = pl
      break
    case 'de':
      image = de
      break
    default:
      // English one
      image = en
      break
  }

  function toggle() {
    setIsOpen(!isOpen)
  }

  return (
      <React.Fragment>
        <div
            className="video-preview"
            onClick={toggle}
            style={{
              backgroundImage: `url(${image})`
            }}/>
        <ModalVideo isOpen={isOpen} toggle={toggle}/>
      </React.Fragment>
  )
}